.StudySection {
  &-header {
    @include clearfix;
    border-bottom: 3px solid #219ebe;
    margin-bottom: $gutter-width;
  }

  &-title {
    text-align: left;
    font-size: 28px;
    color: #333;
    margin-top: 0;
    margin-bottom: 3px;
    float: left;
    line-height: 34px;
  }

  &-content {
    @include clearfix;
  }

  &-tooltip {
    float: right;
    color: #219ebe;
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    .icon::before {
      color: #219ebe;
    }
  }
}
