// TODO: share these variables with usage-percentage
$circle-width: 260px;
$circle-border-width: 7px;

.MeterConsumptionText {
  @include clearfix;
  &-table {
    float: left;
    width: 50%;
    max-height: $circle-width;
    overflow-y: auto;
  }
  &-total {
    float: right;
    width: 50%;
  }
  &-totalContent {
    @include size($circle-width);
    position: relative;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: solid $circle-border-width #ccc;
    margin: 0 auto;
  }
  &-totalValue {
    @include position(absolute, 72px 0 null);
    font-size: 48px;
    text-align: center;
  }
  &-totalCaption {
    @include position(absolute, null null calc(50% - 48px) 50%);
    transform: translateX(-50%);
    font-size: 18px;
  }
}
