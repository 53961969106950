.StudyTable {
  overflow: hidden;
  tbody tr {
    border-bottom: 1px solid color(secondary);
  }

  th,
  td {
    padding: 5px 10px;
    vertical-align: middle;
  }
  &--fat {
    th,
    td {
      @include padding(10px null);
    }
  }

  th {
    @include antialias;
    background-color: color(tertiary);
    color: #fff;
  }
  td {
    font-weight: 300;
    font-size: 16px;
    position: relative;
  }
  input[type="checkbox"] {
    position: relative;
    top: -2px;
    margin: 0;
  }

  .form-item {
    display: inline-block;
  }

  &-row {
    &--active {
      background: #f5f5f5;
      td {
        font-weight: 400;
      }
    }
  }

  .form-item {
    margin: 0;
  }

  &-links {
    margin: 0;
    padding: 0;
    text-align: right;
    li {
      display: inline-block;
      // border-right: solid 1px color(tertiary);
      padding: 0 10px 0 7px;
      margin: 0;
      line-height: 1;
      // &:last-child {
      //   border-right: none;
      // }
    }
    a {
      color: color(primary-light);
    }
  }

  &-fieldSuffix {
    margin-left: 0.5em;
  }

  &-confirmDelete {
    display: table;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: translateX(100%);
    white-space: nowrap;
    transition: transform 0.2s;
    .StudyTable-row--confirmDelete & {
      transform: translateX(0);
    }
  }
  &-confirmDeleteText {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
  }
  &-confirmDeleteActions {
    padding: 0;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
  }
  &-confirmDeleteAction {
    padding: 0;
    margin: 0;
    display: table-cell;
  }
  &-confirmDeleteActionLink {
    line-height: 45px;
    display: inline-block;
    padding: 0 20px;
    &--cancel {
      background-color: #efefef;
      color: #000;
    }
    &--delete {
      @inclue antialias;
      background-color: color(primary);
      color: #fff;
      // font-weight: 400;
      &:hover {
        color: #fff;
      }
    }
  }
}
