.EquipmentForm {
  &-header,
  &-body {
    @include clearfix;
  }
  &-headerField {
    float: left;
    width: 50%;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &-fields {
    float: left;
    width: 50%;
    padding-right: 10px;
    input {
      width: auto !important;
    }
    label {
      display: inline !important;
      margin-left: 5px;
    }
  }
  &-usage {
    float: right;
    width: 50%;
    padding-left: 10px;
  }
}
