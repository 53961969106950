.Overlay {
  @include position(fixed, 0);
  z-index: z(modal);

  ul, li {
    text-transform: none;
    background: none;
    list-style-type: disc;
    color: inherit;
  }

  &-bg {
    @include position(absolute, 0);
    background-color: rgba(0, 0, 0, 0.8);
  }

  &-inner {
    @include position(absolute, 50% null null 50%);
    transform: translateX(-50%) translateY(-50%);
    width: calc(100vw - #{($gutter-width * 2)});
    max-width: $max-width + ($gutter-width * 2);
    padding: $gutter-width;
    max-height: calc(100vh - #{($gutter-width * 2)});
    overflow-y: auto;
    background-color: #fff;
    border-radius: 3px;
  }

  &-close {
    @include position(absolute, 10px 10px null null);
    cursor: pointer;
    font-size: 45px;
    -webkit-text-stroke: 7px white;
    .icon::before {
      color: color(tertiary);
    }
  }

  &-title {
    margin-top: 0;
    color: color(tertiary);
  }

  .StudyFieldgroup {
    margin: 0 auto;
    width: 75%;
  }

  .ajax-progress .message {
    display: none;
  }

  .throbber {
    background-image: none;
    @include spinner(color(primary), $size: 1em) {
      position: absolute;
      display: block;
      bottom: 32px;
      right: 75px;
    };
  }
}
