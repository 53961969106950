$circle-width: 260px;
$circle-border-width: 7px;
$transition-duration: 0.5s; // NOTE: have to keep this in sync with the odometer setting in js

%pie {
  position: absolute;
  border: $circle-border-width solid color(primary);
  width: $circle-width;
  height: $circle-width;
  clip: rect(0, $circle-width / 2, $circle-width, 0);
  border-radius: 50%;
  transform: rotate(0deg);
}

.UsagePercentage {
  @include size($circle-width);
  margin: 20px auto;
  position: relative;
  transition: opacity 0.3s;
  &.is-loading {
    opacity: 0.3;
  }
  &-value {
    @include position(absolute, calc(50% - 10px) null null 50%);
    transform: translateX(-50%) translateY(-50%);
    font-size: 48px;
    width: 100%;
    text-align: center;
    line-height: 1;
  }

  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    transition-duration: $transition-duration;
  }
  &-caption {
    @include position(absolute, null null calc(50% - 48px) 50%);
    transform: translateX(-50%);
    font-size: 18px;
  }

  &-progress {
    position: absolute;
    width: $circle-width;
    height: $circle-width;
    border-radius: 50%;
    background-color: #ccc;

    &::after{
      position: absolute;
      top: $circle-border-width;
      left: $circle-border-width;
      display: block;
      content: "";
      border-radius: 50%;
      background-color: #f5f5f5;
      width: $circle-width - (2 * $circle-border-width);
      height: $circle-width - (2 * $circle-border-width);
    }
  }

  &-mask {
    position: absolute;
    width: $circle-width;
    height: $circle-width;
    clip: rect(0, $circle-width, $circle-width, $circle-width / 2);
    // transition: all $transition-duration ease-in;
    .UsagePercentage.is-overHalf & {
      clip: rect(auto, auto, auto, auto);
    }
  }

  &-bar {
    @extend %pie;
    // TODO: transition is busted when crossing 50%
    transition: transform $transition-duration ease-in;
    .UsagePercentage.is-overHalf &::after {
      transform: rotate(180deg);
    }
  }
  &-fill {
    transition: transform $transition-duration ease-in;
    .UsagePercentage.is-overHalf & {
      @extend %pie;
      transform: rotate(180deg);
    }
  }
}
