@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinner($color: #fff, $size: 12px) {
  $diameter: 1em;
  $border-width: 0.2em;
  $duration: 1s;

  &,
  &:after {
    @include size($diameter);
    border-radius: 50%;
  }
  & {
    display: inline-block;
    border: solid $border-width rgba(0, 0, 0, 0.1);
    border-left-color: $color;
    font-size: $size;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: spinner $duration infinite linear;
    @content;
  }
}
