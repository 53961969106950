$breakpoints: (
  S: 540px,
  M: 960px,
  L: 1440px,
  XL: 1600px,
);

@mixin bp($breakpoint) {
  @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

$gutter-width: 20px;
$max-width: 860px;

$spacer: 4px;
$space-factors: (
  0: 0,
  1: 1,
  2: 2,
  3: 4,
  4: 6,
  5: 12,
);
@function spacer($factor: 3) {
  @return map-get($space-factors, $factor) * $spacer;
}
