.StudyPage {
  max-width: $max-width;
  margin: 0 auto;

  // Ugh...
  ul, li {
    text-transform: none;
    background: none;
    list-style-type: disc;
    color: inherit;
  }

  &-title {
    margin-bottom: 28px;
  }
  &-actions {
    clear: both;
    margin-top: 36px;
    display: table;
    width: 100%;
  }
  &-action {
    display: table-cell;
  }
  &-action {
    &--description {
      text-align: center;
      padding-left: 120px;
    }
    &--submit {
      text-align: right;
      width: 120px;
    }
  }
}
