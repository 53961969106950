$zindex-stack: (
  modal,
  popup,
  tooltip,
) !default;

$zindex-start: 1000 !default;
$zindex-buffer: 100 !default;

@function z($name) {
  @return $zindex-start + ((index($zindex-stack, $name) - 1) * $zindex-buffer);
}
