$colors: (
  primary: #e24126,
  primary-light: #ff9700,
  secondary: #26bad6,
  tertiary: #013741,
);

@function color($color: primary) {
  @return map-get($colors, $color);
}
