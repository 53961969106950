.Stepper {
  display: block;
  width: 100%;
  text-align: center;
  &-step {
    display: inline-block;
    font-size: 16px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    &.is-complete {

    }
    &.is-active {

    }
  }
  &-link {
    color: color(tertiary);
    &--text {

    }
  }
  &-index {
    @include size(25px);
    @include antialias;
    line-height: 25px;
    margin-right: 0.5em;
    text-align: center;
    color: #fff;
    background-color: color(secondary);
    border-radius: 50%;
    display: inline-block;
  }
}
