.u-mxa {
  margin-left: auto !important;
  margin-right: auto !important;
}

@each $prop-key, $prop in (m: margin, p: padding) {
  @each $i, $factor in $space-factors {
    @each $side-key, $side in (t: top, r: right, b: bottom, l: left) {
      .u-#{$prop-key}#{$side-key}#{$i} {
        #{$prop}-#{$side}: spacer($i) !important;
      }
    }

    .u-#{$prop-key}x#{$i} {
      #{$prop}-left: spacer($i) !important;
      #{$prop}-right: spacer($i) !important;
    }
    .u-#{$prop-key}y#{$i} {
      #{$prop}-top: spacer($i) !important;
      #{$prop}-bottom: spacer($i) !important;
    }
    .u-#{$prop-key}a#{$i} {
      #{$prop}: spacer($i) !important;
    }
  }
}
