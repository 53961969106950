.StudyFieldgroup {
  @include clearfix;
  width: 50%;
  padding-right: $gutter-width / 2;

  .form-item {
    margin: 0 0 1.7em;
  }
  .form-item-location-address-city,
  .form-item-location-address-state,
  .form-item-location-address-zip,
  .form-item-meter-address-city,
  .form-item-meter-address-state,
  .form-item-meter-address-zip {
    width: 33.333%;
    float: left;
    padding: 0 10px;
  }
  .form-item-location-address-city,
  .form-item-location-address-city {
    padding-left: 0;
  }
  .form-item-meter-address-zip,
  .form-item-location-address-zip {
    padding-right: 0;
  }
}
