.Button {
  @include antialias;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  user-select: none;
  padding: 0 16px;
  cursor: pointer;
  border: none;
  background-color: color(secondary);
  color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
              0 2px 2px 0 rgba(0,0,0,.14),
              0 1px 5px 0 rgba(0,0,0,.12);
  &--primary {
    background-color: color(primary);
  }
  &--light {
    background-color: #f5f5f5;
    color: #333;
  }
  &--sm {
    height: 28px;
    font-size: 13px;
    padding: 0 8px;
  }
  &--lg {
    height: 44px;
    font-size: 15px;
    padding: 0 32px;
  }
}

// Override base styles
input[type="submit"].Button {
  @extend .Button;
  &--primary {
    @extend .Button--primary;
  }
  &--light {
    @extend .Button--light;
  }
  &--sm {
    @extend .Button--sm;
  }
  &--lg {
    @extend .Button--lg;
  }
}
