body.page-study {
  .header_people {
    display: none;
  }

  input[disabled],
  select[disabled] {
    cursor: default;
    background: #eee;
  }

  .main-wrapper,
  .Overlay {
    font-size: 18px;
    line-height: 1.6;
    label {
      display: block;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 13px;
      margin-bottom: 0.5em;
    }
    input[type="checkbox"],
    input[type="radio"] {
      margin: 0;
      + label {
        text-transform: none;
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        margin-left: 0.5em;
      }
    }

    input[type="text"],
    select {
      width: 100%;
    }
    select {
      height: 32px;
    }

    input[type="text"],
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="number"],
    input[type="month"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select {
      height: 32px;
      color: #333 !important;
      &, textarea {
        width: 100%;
        &:focus {
          border-color: #68a1f1;
        }
      }
    }
  }
}
